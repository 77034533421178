<template>
    <div>
        <div class="row">
            <div class="col-12 col-sm-5 col-md-5 col-lg-5">
                <div class="row h-40">
                    <div class="col-md-5 d-flex align-items-center">
                        <span> Select Patient: </span>
                    </div>
                    <div class="col-md-7">
                        <AsyncSelect
                            placeholder="Patient Name, ID, Mobile No"
                            v-model="patient"
                            :api-service="fetchContactProfiles"
                            :additional-query="additionalQuery"
                            :format-label="formatPatientLabel"
                        />
                    </div>
                </div>

                <div class="row h-40">
                    <div class="col-md-5 d-flex align-items-center">
                        <span> Patient ID: </span>
                    </div>
                    <div class="col-md-7">
                        <span> <strong>{{ patientId }}</strong> </span>
                    </div>
                </div>

                <div class="row h-40">
                    <div class="col-md-5 d-flex align-items-center">
                        <span> Patient Phone No: </span>
                    </div>
                    <div class="col-md-7">
                        <span> <strong> {{patientMobile && patientMobile.value}}</strong></span>
                    </div>
                </div>

                <div class="row h-40">
                    <div class="col-md-5 d-flex align-items-center">
                        <span> Select Admission info: </span>
                    </div>
                    <div class="col-md-7">
                        <v-select
                            placeholder="Select Admission Info"
                            label="text"
                            :options="registrationList"
                            v-model="formData.ipd_register_id"
                            :reduce="text => text.id"
                        />
                    </div>
                </div>

                <div class="row h-40">
                    <div class="col-md-5 d-flex align-items-center">
                        <span> Ward / Bed / Cabin: </span>
                    </div>
                    <div class="col-md-7">
                    <span> <strong>
                        {{ selectedRegisterInfo && selectedRegisterInfo.service_resource.name }}
                    </strong> </span>
                    </div>
                </div>
                <div class="row h-40">
                    <div class="col-md-5 d-flex align-items-center">
                        <span> Consultant / Doctor: </span>
                    </div>
                    <div class="col-md-7">
                    <span><strong>
                        {{ selectedRegisterInfo && selectedRegisterInfo.human_resource.name }}
                    </strong></span>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="openPatientAddModal"
                >New Patient
              </button>
            </div>
            
            <div class="col-12 col-sm-5 col-md-5 col-lg-5">

                <div class="row h-40 mb-5">
                    <div class="col-md-7">
                        <input
                            v-model="patientSerialNo"
                            name="serial"
                            type="number"
                            class="form-control"
                            placeholder="Patient Id"
                            @keyup="searchPatientById"
                        />
                    </div>
                </div>

                <div class="row h-40">
                    <div class="col-md-5 d-flex align-items-center">
                        <span> Invoice Date: </span>
                    </div>

                    <div class="col-md-7">
                        <input
                            class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                            tabindex="1"
                            type="date"
                            placeholder=""
                            v-model="formData.date"
                        >
                    </div>
                </div>

                <div class="row h-40">
                    <div class="col-md-5 d-flex align-items-center">
                        <span> Ship from (Warehouse): </span>
                    </div>

                    <div class="col-md-7">
                        <v-select
                            placeholder="Select Warehouse Location"
                            v-model="formData.location_id"
                            :options="locations"
                            label="text"
                            :reduce="text => text.id"
                        />
                    </div>
                </div>
            </div>
        </div>

        <AddPatientModal
            v-if="$store.state.isModalOpenTwo"
            :doctors="doctors"
            @onCreateProfile="onCreateProfile"
        />

        <Loader v-if="isLoader" />
    </div>
</template>

<script setup>
import {computed, inject, onMounted, ref, watch, reactive} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import { useStore } from 'vuex';
import handleBusinessesLocations from '@/services/modules/businessesLocations'
import handleContact from '@/services/modules/contact'
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import handleHospitalBilling from "@/services/modules/hospital/billing";
import AddPatientModal from '@/components/molecule/company/hospital/AddPatientModal'
import useDate from "@/services/utils/day";
import handleHospital from '@/services/modules/hospital'
import Loader from "@/components/atom/LoaderComponent";

const props = defineProps({
  addNewItem: {
    type: Function
  },
  tableItems: {
    type: Array
  }
})

const $route = useRoute();
const $router = useRouter();
const $store = useStore();
const date = useDate();
const patient = ref(null)
const userInfo = ref(null);
const isLoader = ref(false);
const patientSerialNo = ref(null);
const companyId = $route.params.companyId
const companyQuery = `?company_id=${companyId}`;
const selectedData = ref({});
const formData = inject('formData');
const showError = inject("showError")
let locations = ref([]);
const registrationList = ref([]);
const additionalQuery = {
    type: "patient"
}
const doctors = reactive([]);

const {fetchBusinessLocationsList} = handleBusinessesLocations()
const {fetchContactProfiles, fetchSingleContactProfile} = handleContact()
const { fetchIpdRegisterList } = handleHospitalBilling()
const { formatPatientLabel } = useAsyncDropdownHelper()
const { fetchPatientDetailsBySerial } = handleHospital()

const selectedRegisterInfo = computed(() => {
    if(! formData.value.ipd_register_id) return null
    return registrationList.value.find(registration => registration.id === formData.value.ipd_register_id)
})

const patientMobile = computed(() => {
    if (selectedData.value && selectedData.value.profile_items && selectedData.value.profile_items.length) {
        return selectedData.value.profile_items.find(item => item.field === 'mobile_no')
    }
    return null
})

const patientId = computed(() => {
    if (
        selectedData.value &&
        selectedData.value.company_roles &&
        selectedData.value.company_roles.length &&
        selectedData.value.company_roles[0].pivot
    ) {
        return selectedData.value.company_roles[0].pivot.serial_no
    }
    return null
})

const openPatientAddModal = () => {
    const query = `?company_id=${companyId}&type=doctor`
    fetchContactProfiles(query).then((res) => {
        if(res.status) {
            doctors.length = 0;
            Object.assign(doctors, res.data);
        }
    }).then(() => {
        $store.state.isModalOpenTwo = true
    })
}

const searchPatientById = async () => {

    if(!patientSerialNo.value) return;

    userInfo.value = null
    patient.value = null
    isLoader.value = true;

    const query = `?company_id=${companyId}`;

    await fetchPatientDetailsBySerial(query, patientSerialNo.value)
        .then((res) => {
            
            if (!res.status) {
                return showError(res.message)
            }
            
            userInfo.value = res.data
            patient.value = {
                id: userInfo.value.id,
                name: userInfo.value.full_name
            }
        })
    
    isLoader.value = false;
}

const onCreateProfile = (profileInfo) => {
    patient.value = profileInfo;
    patient.value.name = profileInfo.full_name
}

const generateDateAndInvoice = () => {
    formData.value.date = date.currentDate();
}

const resetData = () => {
    generateDateAndInvoice();
    patient.value = null;
    formData.value.location_id = locations.value[0].id
}

onMounted(async () => {
    generateDateAndInvoice();
    
    await fetchBusinessLocationsList(companyQuery).then(res=> {
      if(res.data) {
          locations.value = res.data
          formData.value.location_id = res.data[0].id
      }
    })
})

watch(patient, () => {
    const patientQuery = companyQuery + '&type=patient';
    formData.value.contact_profile_id = null;
    selectedData.value = null;
    registrationList.value = [];

    if (patient.value) {
        formData.value.contact_profile_id = patient.value.id;
        const ipdRegisterRes = fetchIpdRegisterList(`${companyQuery}&contact_profile_id=${patient.value.id}`)
        fetchSingleContactProfile(patient.value.id, patientQuery).then(res => {
            if (res.data) {
                selectedData.value = formData.value.contact = res.data;
            }
        })
        ipdRegisterRes.then(res => {
            if(res.data) registrationList.value = res.data
        })
    }
})

watch(selectedRegisterInfo, (newValue) => {
    if (newValue) {
        formData.value.service_resource_id = "human_resource" in newValue ? newValue.human_resource.id : null
    }
})

defineExpose({
    resetData
})

</script>

<style>
.select label{
    font-size: 14px;
}
.h-40{
    height: 40px;
    align-items: center;
}
</style>